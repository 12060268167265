declare var noUiSlider: any;
declare var priceMin: any;
declare var priceMax: any;
declare var valentine_dates: string;
declare var march8_dates: string;

interface IAjaxFiltersFormElements extends HTMLCollection {
  date: HTMLInputElement; /* Дата доставки */
  price_low: HTMLInputElement; /* Цена от */
  price_high: HTMLInputElement; /* Цена до */
  flowers: HTMLSelectElement; /* Цветы */
  filter: HTMLInputElement; /* SEO (метрика) */
}

function ajaxFiltersInit() {
  var form: HTMLFormElement, elements: IAjaxFiltersFormElements,
    inputDate: JQuery, inputPriceMin: JQuery, inputPriceMax: JQuery,
    selectFlowers: JQuery;

  var priceRange: any = document.getElementById('price-range');

  if (!priceRange) {
    return;
  }

  form = document.querySelector('form[name=ajaxfilters]');
  elements = <IAjaxFiltersFormElements> form.elements;

  inputDate = $(elements.date);
  inputPriceMin = $(elements.price_low);
  inputPriceMax = $(elements.price_high);
  selectFlowers = $(elements.flowers);
  var start = [priceMin, priceMax];

  function _getUri(): string {
    var uri: string = '/bukety/';
    var filterParams: string[] = [];
    var dateValue: string = <string> inputDate.val();

    if (dateValue) {
      if (-1 !== valentine_dates.search(dateValue)) {
        uri = '/tsvety-na-den-svyatogo-valentina/';
      }

      if (-1 !== march8_dates.search(dateValue)) {
        uri = '/tsvety-na-8-marta/';
      }
    }

    if ('0' != selectFlowers.val()) {
      uri += selectFlowers.val() + '/';
    }

    if (inputDate.val() !== '') {
      filterParams.push('date=' + inputDate.val());
    }

    if ((inputPriceMin.val() !== priceMin.toString()) && (inputPriceMin.val() !== '')) {
      filterParams.push('price-low=' + inputPriceMin.val());
    }

    if ((inputPriceMax.val() !== priceMax.toString()) && (inputPriceMax.val() !== '')) {
      filterParams.push('price-high=' + inputPriceMax.val());
    }

    filterParams.push('filter=main-' + $(elements.filter).val());

    if (filterParams.length > 0) {
      uri += '?' + filterParams.join('&');
    }

    return uri;
  }

  function _update() {
    var uri: string, buttonSubmit: JQuery;

    uri = _getUri();
    buttonSubmit = $(form).find('.js-submit');

    buttonSubmit.attr('disabled', 'disabled');

    $.ajax({
      url: uri,
      method: 'post',
      dataType: 'json',
      data: {},
      success: (data: any) => {
        buttonSubmit.html('Показать (' + (data.total_count > 99 ? '99+' : data.total_count) + ')');

        if (0 < data.total_count) {
          buttonSubmit.removeAttr('disabled').removeClass('disabled');
        } else {
          buttonSubmit.addClass('disabled')
        }
      },
      error: () => {
        buttonSubmit.html('Показать (0)').addClass('disabled')
      }
    });
  }

  noUiSlider.create(priceRange, {
    start: start,
    behaviour: 'drag-tap',
    step: 1,
    connect: true,
    range: {
      'min': [priceMin],
      '70%': [15000],
      'max': priceMax
    }
  });

  inputPriceMin.on('change', () => {
    priceRange.noUiSlider.set([inputPriceMin.val(), null]);
    _update();
  });

  inputPriceMax.on('change', () => {
    priceRange.noUiSlider.set([null, inputPriceMax.val()]);
    _update();
  });

  priceRange.noUiSlider.on('update', function (values: any, handle: any) {
    var value = values[handle];
    setTimeout(function () {
      value = Math.round(value);

      if (value < priceMax && value > priceMin) {
        if (value % 100 !== 0)
          value = Math.round(value / 100) * 100;
      }

      if (handle) {
        if (value.toString() === inputPriceMax.attr('max'))
          value = '';
        inputPriceMax.val(value);
      } else {
        if (value.toString() === inputPriceMin.attr('min'))
          value = '';
        inputPriceMin.val(value);
      }
    }, 0);
  });

  priceRange.noUiSlider.on('change', function () {
    _update();
  });

  var fsCalendar = new FsCalendar(<HTMLInputElement> document.getElementById("v-input-calendar"));

  fsCalendar.onchange = function () {
    var date: Date, d: number, m: number;

    date = fsCalendar.getDate();

    d = date.getUTCDate();
    m = date.getUTCMonth() + 1;

    $("#f_deldate").val(date.getUTCFullYear().toString() + (9 < m ? m.toString() : "0" + m.toString()) + (9 < d ? d.toString() : "0" + d.toString()));

    _update();
  };

  selectFlowers.change(function () {
    _update();
  });

  $(form).find('.js-set-date').on('click', function () {
    var date, d, m, needleDate;
    needleDate = $(this).data("date");

    fsCalendar.setDate(needleDate);
    date = fsCalendar.getDate();

    d = date.getUTCDate();
    m = date.getUTCMonth() + 1;
    $("#v-input-calendar").val((9 < d ? d : "0" + d) + "." + (9 < m ? m : "0" + m) + "." + date.getUTCFullYear());
    $("#f_deldate").val(date.getUTCFullYear().toString() + (9 < m ? m : "0" + m) + (9 < d ? d : "0" + d));

    _update();

    return false;
  });

  $(form).find('.js-set-price').on('click', function () {
    var min = $(this).data("min");
    var max = $(this).data("max");

    inputPriceMin.val(min);
    inputPriceMax.val(max);

    priceRange.noUiSlider.set([min, max]);

    _update();

    return false;
  });

  $(form).submit(() => {
    var uri: string = _getUri();
    document.location.href = uri;
    return false;
  });
}
